import { CorrLanguage } from '../../models/corr-language.enum';
import { LanguageEnum } from '../../models/Enums/language';
import { Identifier } from '../../models/storage.models';
import { LanguageStatus } from '../model/language-status.model';

export function getLanguageStatus(): LanguageStatus {
  const sysCorrLanguageIds = JSON.parse(
    localStorage.getItem(Identifier.TYPE_OTHER_SUPPORTED_LANGUAGES) || '{}'
  );
  if (sysCorrLanguageIds && sysCorrLanguageIds.length > 0) {
    return {
      fr: sysCorrLanguageIds.includes(LanguageEnum.fr),
      es: sysCorrLanguageIds.includes(LanguageEnum.es),
      de: sysCorrLanguageIds.includes(LanguageEnum.de),
    };
  }

  return { fr: true, de: true, es: true };
}


export function getOtherSupportedLanguageIds() {
  return JSON.parse(
    localStorage.getItem(Identifier.TYPE_OTHER_SUPPORTED_LANGUAGES) || '{}'
  );
}
